import { Link, Typography } from '@mui/material'
import { useCustomGoSchool } from '@goschool/components'

export function Welcome() {
  const {primaryLogo} = useCustomGoSchool()
  return <>
    <img src={primaryLogo} alt="Óbudai Egyetem" style={{ maxWidth: '100%', height: 'auto' }} />
    <Link href="https://goschool.ai" target="_blank"><Typography style={{ textDecoration: 'none' }} variant="body1">powered
      by goschool.ai</Typography></Link>
  </>
}
