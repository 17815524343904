import { type FormEvent, useMemo, useState } from 'react'
import { useCallback } from 'react'
import {
  Button, DialogActions, DialogContent, DialogTitle, FormHelperText,
  TextField
} from '@mui/material'

import { Trans, useTranslation } from 'react-i18next'
import { useUserContext } from '@goschool/auth'
import { GoSchoolDialog } from '@goschool/mui'
import { LoadingButton } from '@mui/lab'
import { arrayUnion, collection, doc, serverTimestamp, writeBatch } from 'firebase/firestore'
import { LanguageSelector } from './LanguageSelector'
import { typeConverter, useFirebaseAnalytics, useFirestore } from '@progos/firebase-chat'
import type { Course, GoSchoolUser } from '@goschool/model'
import { logEvent } from 'firebase/analytics'
import { useSentry } from '@goschool/components'

interface CreateCourseFormProps {
  displayed: boolean;
  hide: () => void;
}

interface CourseFields {
  title: string;
  code: string;
  description: string;
  language_code: string;
}

interface CourseFieldErrors {
  title?: string;
  code?: string;
  description?: string;
  language_code?: string;
}

type submitState = 'idle' | 'pending' | 'success' | 'failed';

export function CreateCourseDialog({ displayed, hide }: CreateCourseFormProps) {
  const { goSchoolUser, user } = useUserContext()

  const firestore = useFirestore()
  const userRef = useMemo(
    () => user
      ? doc(firestore, 'users', user.uid).withConverter(typeConverter<GoSchoolUser>())
      :null,
    [firestore, user]
  )


  const { analytics } = useFirebaseAnalytics()
  const [fields, setFields] = useState<CourseFields>({
    title: '',
    code: '',
    description: '',
    language_code: 'hu'
  })
  const [fieldErrors, setFieldErrors] = useState<CourseFieldErrors>({})
  const [error, setError] = useState<string | null>(null)
  const [state, setState] = useState<submitState>('idle')

  const setField = useCallback((key: keyof CourseFields, value: string) => {
    setFields(fields => ({ ...fields, [key]: value }))
  }, [])

  const { t } = useTranslation()
  const reportError = useSentry()

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (state!=='idle') {
        return
      }

      if (goSchoolUser?.organization==null || user==null || userRef==null) {
        return
      }
      setState('pending')
      setError(null)
      setFieldErrors({})

      const batch = writeBatch(firestore)
      const coursesCollection =
        collection(goSchoolUser.organization, 'courses')
          .withConverter(typeConverter<Course>())
      const courseData = {
        title: fields.title,
        code: fields.code,
        language_code: fields.language_code,
        description: fields.description,
        owner: user.uid,
        created_at: serverTimestamp()
      }
      try {
        const courseRef = doc(coursesCollection)
        const instructorRef = doc(courseRef, 'instructors', user.uid)
        batch.set(courseRef, courseData)
        batch.update(userRef, {'courses': arrayUnion(courseRef)})
        batch.set(instructorRef, { name: user.displayName, user: userRef })
        batch.commit()
        hide()
        setState('success')
        logEvent(analytics, 'create_course', {
          course_id: courseRef.id
        })
      } catch (e) {
        reportError(e)

        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('An unknown error occurred')
        }
        setState('failed')
      }
    }, [state, goSchoolUser, user, userRef, firestore, fields, hide, analytics, reportError]
  )

  const cancel = useCallback(
    () => hide(),
    [hide]
  )

  return <GoSchoolDialog open={displayed} maxWidth="md" fullWidth={true} onClose={hide}>
    <form onSubmit={submit}>

      <DialogTitle><Trans i18nKey="course:forms.create.title" /></DialogTitle>
      <DialogContent>
        <TextField
          size="small" fullWidth={true} margin="normal"
          label={t('course:forms.edit.fields.title')} value={fields.title ?? ''}
          error={fieldErrors.title!=null} helperText={fieldErrors.title}
          onChange={e => setField('title', e.target.value)} />

        <TextField
          size="small" fullWidth={true} margin="normal"
          label={t('course:forms.edit.fields.code')} value={fields.code ?? ''}
          error={fieldErrors.code!=null} helperText={fieldErrors.code}
          onChange={e => setField('code', e.target.value)} />

        <LanguageSelector languageCode={fields.language_code} setLanguageCode={
          (language) => setField('language_code', language)
        } label={t('course:forms.edit.fields.language')} />


        <TextField
          size="small" fullWidth={true} multiline={true} margin="normal"
          rows={3}
          error={fieldErrors.description!=null} helperText={fieldErrors.description}
          label={t('course:forms.edit.fields.description')} value={fields.description ?? ''}
          onChange={e => setField('description', e.target.value)} />

        {error!=null && <FormHelperText error={true}>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <LoadingButton sx={{ flexShrink: 0 }} type="submit" variant="contained" color="primary"
                       loading={state==='pending'}
                       disabled={['success', 'pending'].includes(state) || goSchoolUser?.organization==null ||
                         (fields.title ?? '').trim()==='' ||
                         (fields.code ?? '').trim()==='' ||
                         (fields.description ?? '').trim()===''
                       }
                       autoFocus={true} onClick={submit}>
          <Trans i18nKey="course:forms.create.submit" />
        </LoadingButton>
        <Button variant="outlined" sx={{ textTransform: 'inherit' }} onClick={cancel} size="small"><Trans
          i18nKey="course:forms.create.cancel" /></Button>
      </DialogActions>
    </form>
  </GoSchoolDialog>
}



