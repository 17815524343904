import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { PageLayout } from '@goschool/components'
import { Trans, useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { GoSchool } from '@goschool/routing'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { collectionGroup, orderBy, query } from 'firebase/firestore'
import { doc } from 'firebase/firestore'
import type { Course, CourseChat, GoSchoolUser, Organization } from '@goschool/model'
import {
  typeConverter,
  useFirestore,
  useFirestoreResults,
  useFirestoreSnapshot
} from '@progos/firebase-chat'
import { TransRelTime } from '@goschool/i18n'
import { Link as RouterLink } from 'react-router-dom'


function allChatsBreadcrumbs() {
  return [
    {title:'Admin', href: GoSchool.admin},
    {
      title:'Chats',
      href: '/admin/chats'
    }
  ]
}

export function AllChatsPage() {

  const { t } = useTranslation()




  return <PageLayout
    fullScreen={false}
    breadcrumbs={allChatsBreadcrumbs()}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:chats.title" /></Typography>
    <AllChatsTable />
  </PageLayout>
}


function AllChatsTable() {
  const firestore = useFirestore()
  const chatsRef = useMemo(
    () =>
      query(
        collectionGroup( firestore, 'chats'),
        orderBy('updated_at', 'desc')
      )
        .withConverter(typeConverter<CourseChat>()),
    [firestore]
  )

  const chats = useFirestoreResults(chatsRef)
  const { t } = useTranslation()

  return <TableContainer component={Paper} sx={{ overflow: 'hidden',maxWidth: '100%' }} >
    <Table size="small" sx={{maxWidth: '100%', tableLayout: 'auto'}}>
      <TableHead>
        <TableRow>
          <TableCell>{t('course:chats.tableHeaders.course')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.title')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.user')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.lastMessage')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(chats ?? []).map((chat) => <ChatRow key={chat.id} snapshot={chat} />)}
      </TableBody>
    </Table>
  </TableContainer>
}


function ChatRow({ snapshot }: { snapshot: QueryDocumentSnapshot<CourseChat> }) {
  const chat = useMemo(() => snapshot.data(), [snapshot])
  const firestore = useFirestore()
  const userRef = useMemo(
    () =>
      chat.created_by!=null
        ? doc(firestore, 'users', chat.created_by).withConverter(typeConverter<GoSchoolUser>())
        :null,
    [chat, firestore]
  )
  const userSnapshot = useFirestoreSnapshot(userRef)
  const orgSnapshot = useFirestoreSnapshot(snapshot.ref.parent.parent?.parent.parent?.withConverter(typeConverter<Organization>()))
  const courseSnapshot = useFirestoreSnapshot(snapshot.ref.parent.parent?.withConverter(typeConverter<Course>()))

  return <TableRow>
    <TableCell title={orgSnapshot?.data()?.name}>{courseSnapshot?.data()?.title}</TableCell>
    <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '400px', textOverflow:'ellipsis' }}>
      <Link to={GoSchool.courseChatView(snapshot.ref)} component={RouterLink}>
        {chat.title ?? 'New Chat'}
      </Link>
    </TableCell>
    <TableCell>{userSnapshot?.data()?.name}</TableCell>
    <TableCell><TransRelTime ts={chat.updated_at} /> </TableCell>
  </TableRow>
}
