import { colors, Container, styled } from '@mui/material'
import type { PropsWithChildren } from 'react'

export const Scroller = styled('div', {
  name: 'Scroller',
  slot: 'Root'
})(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
    overflowY: 'scroll',
    scrollSnapType: 'y mandatory',
    scrollBehavior: 'smooth'
  }
}))

export function Section({ children, backgroundVideo }: PropsWithChildren<{ backgroundVideo?: string }>) {
  if (backgroundVideo) {
    return <SectionBox className="video-bg">
      <video className="SectionBbx-background" autoPlay loop muted playsInline>
        <source src={backgroundVideo}/>
      </video>
      {children}</SectionBox>
  }
  return <SectionBox><Container maxWidth="md">{children}</Container></SectionBox>
}

const SectionBox = styled('div', {
  name: 'SectionBox',
  slot: 'Root'
})(({ theme }) => ({
  '&:nth-of-type(even):not(.video-bg)': {
    backgroundColor: colors.indigo[900],
    '& .MuiTypography-root': {
      color: theme.palette.common.white
    }
  },
  '& .MuiContainer-root': {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  [theme.breakpoints.up('md')]: {
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'scroll',
    scrollSnapAlign: 'start'
  },
  position: 'relative',
  '& video.SectionBbx-background': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1
  }
}))
