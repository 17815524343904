import type {
  QueryDocumentSnapshot} from 'firebase/firestore'
import {
  collection,
  doc,
  type DocumentReference,
  orderBy,
  query,
  where
} from 'firebase/firestore'
import type { Course, CourseChat } from '@goschool/model'
import { useUserContext } from '@goschool/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { typeConverter, useFirestoreResults } from '@progos/firebase-chat'
import { useChatManager } from './useChatManager'

export function useChats(courseSnapshot: QueryDocumentSnapshot<Course>, handleChatSelect?: () => void) {
  const { user } = useUserContext()
  const collectionRef = useMemo(
    () =>
      collection(courseSnapshot.ref, 'chats')
        .withConverter(typeConverter<CourseChat>()),
    [courseSnapshot]
  )

  const chatsRef = useMemo(
    () => {
      if (user==null) {
        return null
      }
      return query(
        collectionRef,
        where('participants', 'array-contains', user.uid),
        orderBy('updated_at', 'desc')
      )
    },
    [collectionRef, user]
  )


  const chats = useFirestoreResults(chatsRef, { waitForWrites: true })


  const [selectedChat, setSelectedChat] = useState<DocumentReference<CourseChat>>()

  const selectChat = useCallback(
    (chat: DocumentReference<CourseChat>) => {
      setSelectedChat(chat)
      handleChatSelect?.()
    },
    [setSelectedChat, handleChatSelect]
  )

  const createChat = useCallback(
    () => selectChat(doc(collectionRef)),
    [collectionRef, selectChat]
  )

  useEffect(
    () => {
      if (chats!=null) {
        if (selectedChat==null) {
          if (chats.length > 0) {
            selectChat(chats[0].ref)
          } else {
            createChat()
          }
        }
      }
    },
    [selectedChat, chats, createChat, selectChat]
  )

  const chatManager = useChatManager(user, selectedChat)


  return { collectionRef, chats, selectedChat, selectChat, createChat, chatManager }
}
