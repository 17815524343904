import { isAgentMessage, isUserMessage } from '../model'
import React, { useEffect, useMemo, useRef } from 'react'
import type { DocumentSnapshot } from 'firebase/firestore'
import type { List } from '@mui/material'
import {
  Alert,
  Avatar,
  Box,
  Button, Chip,
  Stack,
  styled,
  Typography
} from '@mui/material'
import type {
  AgentMessage,
  ChatMessage,
  UserMessage
  , ChatCompletionMessageToolCall
  , ChatCompletionToolMessageParam
} from '../model'
import AiIcon from './assets/goschool-ai.svg'
import PersonIcon from '@mui/icons-material/Person'
import { MessageMarkdownComponent } from './MarkdownMessageComponent'
import { useFirebaseAuth } from '@progos/firebase-chat'
import { useChatContext } from './ChatContext'
import type { MessageTree } from '../model/messageThread'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

interface TreeViewProps {
  tree: MessageTree;
  activeChild: MessageTree | null;
}

export function ThreadView({ tree, activeChild }: TreeViewProps) {
  const chatContext = useChatContext()
  const { chatManager } = chatContext
  const { message, children } = tree

  return (
    <>
      <ChatMessageView message={message} />
      {children.length > 1 && (
        <Stack direction="row" spacing={2} sx={{ pl: 4 }}>
          {children.map((c, i) => (
            <Button key={c.message.id}
                    disabled={c===activeChild}
                    variant="text" color="primary"
                    onClick={() => chatManager.selectThread(c)}>
              {i + 1}
            </Button>
          ))}
        </Stack>
      )}
    </>
  )
}

function ChatMessageView({ message }: { message: DocumentSnapshot<ChatMessage>; }) {
  const messageData = useMemo(() => message.data(), [message])
  if (messageData===undefined) {
    throw new Error('Message data is undefined')
  }
  if (isUserMessage(messageData)) {
    return <UserMessageView message={messageData} />
  } else if (isAgentMessage(messageData)) {
    return <AgentMessageView message={messageData} />
  } else {
    throw new Error('Unknown message type')
  }
}

function UserMessageView({ message }: { message: UserMessage }) {
  const { user } = useFirebaseAuth()
  const { chatManager } = useChatContext()

  if (chatManager===undefined) {
    throw new Error('Chat is undefined')
  }

  const userTitle = useMemo(
    () => (message.user===user?.uid ? 'You':'User'),
    [user, message]
  )
  return (
    <MessageContainer>
      <Stack direction="row" alignItems="center" spacing={1}>
        <SenderAvatar className="human">
          <PersonIcon />
        </SenderAvatar>
        <Typography variant="body1" component="h3" fontWeight="bold">
          {userTitle}
        </Typography>
      </Stack>
      <MessageContentContainer>
        <MessageContent>{message.content}</MessageContent>
      </MessageContentContainer>
    </MessageContainer>
  )
}


function AgentMessageView({ message }: { message: AgentMessage }) {
  return (
    <MessageContainer>
      <Stack direction="row" alignItems="center" spacing={1}>
        <SenderAvatar className="agent" alt="user">
          <img src={AiIcon} width={24} height={24} alt="agent" />
        </SenderAvatar>
        <Typography variant="body1" component="h3" fontWeight="bold">
          GoSchool
        </Typography>
      </Stack>
      {/*<ToolUsage message={message} />*/}
      <MessageContentContainer>
        <AIMessageContent message={message} />
      </MessageContentContainer>
      {message.status==='generating' && (
        <AutoScrollToBottom message={message} />
      )}
    </MessageContainer>
  )
}


function ToolUsage({ message }: { message: AgentMessage }) {


  const tool_calls = useMemo(
    () => {
      if (message.messages===undefined) {
        return []
      }
      const tool_calls = new Map<string, {
        call: ChatCompletionMessageToolCall,
        result?: ChatCompletionToolMessageParam
      }>()

      for (const m of message.messages) {
        if (m.role==='assistant' && m.tool_calls!=null) {
          for (const tc of m.tool_calls) {
            tool_calls.set(tc.id, { call: tc })
          }
        } else if (m.role==='tool') {
          if (m.tool_call_id!=null) {
            const tc = tool_calls.get(m.tool_call_id)
            if (tc!=null) {
              tc.result = m
            }
          }
        }
      }
      return tool_calls
    },
    [message]
  )


  return <Stack direction="row" gap={1}>{Array.from(tool_calls.values()).map(
    ({ call, result }) => {
      switch (call.function.name) {
        case 'search':
          if (result==null) {
            return null
          }

          return <SearchResults key={call.id} call={call} result={result} />
        case 'course_info':
          return <Chip icon={<InfoOutlinedIcon />} key={call.id} label="Course Info" size="small"
                       variant="outlined" />
        case 'course_documents':
          return <Chip icon={<MenuBookIcon />} key={call.id} label="Course Documents" size="small"
                       variant="outlined" />
        default:
          return null
      }
    }
  )}  </Stack>
}


interface RetrievalHit {
  score: number;
  content: string;
  reference?: {
    title: string
    authors: string[]
    page?: number
    start_offset?: number
  };
}

function SearchResults({ call, result }: {
  call: ChatCompletionMessageToolCall,
  result: ChatCompletionToolMessageParam
}) {
  const queries = useMemo(
    () => {
      const args = JSON.parse(call.function.arguments)
      if (args.queries==null) {
        return null
      }

      return args.queries.map(
        (q: { query?: string }) => q.query
      ).filter((q: string | undefined) => q!=null).join(', ')
    }, [call.function.arguments]
  )


  const hits = useMemo(
    () => {
      if (result==null) {
        return null
      }
      try {
        const hits = JSON.parse(result.content) as RetrievalHit[]
        if (Array.isArray(hits)) {
          return hits
        } else {
          return null
        }
      } catch (e) {
        return null
      }
    }, [result]
  )

  if (hits==null) {
    return <Chip sx={{maxWidth: 50}} icon={<SearchIcon />} key={call.id} label={queries} size="small" variant="outlined" />
  }
  return <>{hits.filter(h=> h.reference!= null).map(
    (hit, i) => <Chip icon={<TextSnippetIcon />} key={i + 1000} label={`${hit.reference?.title} p${hit.reference?.page}`} size="small" variant="outlined" />
  )}</>

}

function AutoScrollToBottom({ message }: { message: AgentMessage }) {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (message.status==='generating') {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [message])

  return <div ref={ref} style={{ flexShrink: 0 }}></div>
}

function AIMessageContent({ message }: { message: AgentMessage }) {
  switch (message.status) {
    case 'pending':
      return <Pending />
    case 'generating':
      if (message.content==='') {
        return <Pending />
      }
      return <MessageContent>{message.content}</MessageContent>
    case 'completed':
      return <MessageContent>{message.content}</MessageContent>
    case 'failed':
      return (
        <Alert severity="error">
          An error occurred while generating this message. Please try again.
        </Alert>
      )
  }
}

function MessageContent({ children }: { children: string | null | undefined }) {
  const { ContentComponent } = useChatContext()

  if (ContentComponent===undefined) {
    return <MessageMarkdownComponent>{children}</MessageMarkdownComponent>
  }
  return <ContentComponent>{children}</ContentComponent>
}

const SenderAvatar = styled(Avatar, { name: 'SenderAvatar', slot: 'Root' })(
  ({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.human': {
      backgroundColor: theme.palette.warning.main
    },
    '&.agent': {
      backgroundColor: theme.palette.background.default
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    }
  })
)

const MessageContainer = styled(Box, {
  name: 'MessageContainer',
  slot: 'Root'
})(({ theme }) => ({
  // backgroundColor: theme.palette.grey[100],
  // border: `1px solid ${theme.palette.divider}`,
  //padding: theme.spacing(2)
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

const MessageContentContainer = styled(Box, {
  name: 'MessageTextContainer',
  slot: 'Root'
})(({ theme }) => ({
  paddingLeft: theme.spacing(4)
}))

function Pending() {
  const { duration, begin } = useMemo(() => {
    const dur = 1
    return {
      duration: `${dur}s`,
      begin: [
        `0;dot2.end-${dur / 3}s`,
        `dot0.end-${dur * 0.8}s`,
        `dot0.end-${dur * 0.6}s`
      ]
    }
  }, [])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="2rem"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <circle cx={4} cy={12} r={3}>
          <animate
            id="dot0"
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[0]}
          />
        </circle>
        <circle cx={12} cy={12} r={3}>
          <animate
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[1]}
          />
        </circle>
        <circle cx={20} cy={12} r={3}>
          <animate
            id="dot2"
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[2]}
          />
        </circle>
      </g>
    </svg>
  )
}
