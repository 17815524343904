import type { PropsWithChildren} from 'react'
import { useContext } from 'react'
import { createContext } from 'react'

export interface CustomGoSchoolConfig {
  primaryLogo: string;
  secondaryLogo: string;
}

export const CustomGoSchoolContext = createContext<CustomGoSchoolConfig | undefined>(undefined)

export type CustomGoSchoolContextProviderProps = PropsWithChildren & CustomGoSchoolConfig

export function CustomGoSchoolContextProvider(props: CustomGoSchoolContextProviderProps) {
  const { children, ...config } = props
  return <CustomGoSchoolContext.Provider value={config}>{children}</CustomGoSchoolContext.Provider>
}

export function useCustomGoSchool() {
  const context = useContext(CustomGoSchoolContext)
  if (!context) {
    throw new Error('useCustomGoSchool must be used within a CustomGoSchoolContextProvider')
  }
  return context
}
