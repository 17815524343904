import type { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore'
import type { CourseDocument, CourseDocumentState} from '@goschool/model'
import { useFunctions } from '@goschool/model'
import {
  Box, IconButton,
  LinearProgress,
  Link, Stack,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { GoSchool } from '@goschool/routing'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import QuizIcon from '@mui/icons-material/Quiz'
import DeleteIcon from '@mui/icons-material/Delete'
import { useUserContext } from '@goschool/auth'

export function CourseDocumenList({ courseDocuments }: { courseDocuments: QueryDocumentSnapshot<CourseDocument>[] }) {
  return <Stack spacing={2}>{courseDocuments.map(
    (documentSnapshot) =>
      <CourseDocumenItem
        key={documentSnapshot.id}
        documentSnapshot={documentSnapshot} document={documentSnapshot.data()} />
  )}</Stack>
}

function CourseDocumenItem({ documentSnapshot, document }: {
  documentSnapshot: QueryDocumentSnapshot<CourseDocument>, document: CourseDocument
}) {
  const authors = useMemo(
    () => {
      if (document.authors==null || document.authors.length===0) {
        return null
      } else {
        return document.authors?.join(', ')
      }
    },
    [document]
  )
  return <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6" component="h4" display="flex" alignItems="center" gap={0.5}><CourseDocumentIcon
        document={document} /> <Link href={GoSchool.document(documentSnapshot.ref)}>{document.title}</Link></Typography>
      <CourseDocumentActions documentSnaphot={documentSnapshot} />
    </Stack>
    <DocumentState document={document} />
    <Typography variant="subtitle2">{authors}</Typography>
    <Typography variant="body2">{document.lead}</Typography>

  </Stack>
}

function CourseDocumentActions({ documentSnaphot }: { documentSnaphot: QueryDocumentSnapshot<CourseDocument> }) {
  return <Stack direction="row">
    <DeleteDocumentButton documentRef={documentSnaphot.ref} />
  </Stack>
}

function DeleteDocumentButton({ documentRef }: { documentRef: DocumentReference<CourseDocument> }) {
  const { roles } = useUserContext()

  const { deleteDocument } = useFunctions()
  const { t } = useTranslation()

  const handleDelete = useCallback(
    async () => {
      try {
        if (window.confirm(t('document:actions.confirm-delete'))) {
          await deleteDocument(documentRef)
        }
      } catch (error) {
        console.error(error)
      }
    }, [deleteDocument, documentRef, t]
  )

  if (roles.includes('admin') || roles.includes('instructor')) {
    return <IconButton sx={{ padding: 0 }} size="small" onClick={handleDelete}><DeleteIcon fontSize="small" /></IconButton>
  }
}

function CourseDocumentIcon({ document }: { document: CourseDocument }) {
  if (document.content_type==='application/pdf') {
    return <PictureAsPdfIcon fontSize="small" />
  } else if (document.content_type?.startsWith('audio/') || document.content_type?.startsWith('video/')) {
    return <LibraryMusicIcon fontSize="small" />
  } else {
    return <QuizIcon fontSize="small" />
  }
}

export function DocumentState({ document }: { document: CourseDocument }) {

  const state = useMemo(
    () => {
      const state: CourseDocumentState = document.state ?? {}
      const transitions: (keyof CourseDocumentState)[] =
        ['preprocess_upload', 'extract_content', 'transcribe_audio', 'content_analysis', 'index_content']


      let step = 0
      for (const key of transitions) {
        const keyState = state[key]
        if (keyState?.status==='succeeded') {
          step++
        }
        if (keyState!=null && (keyState.status!=='succeeded' || key==='index_content')) {
          const { progress, status, total } = keyState
          return {
            transition: key, status, progress, total, step
          }
        }
      }
      return {
        transition: 'pending', status: 'processing', step
      }
    }, [document])


  switch (state.transition) {
    case 'pending':
      return <State {...state} steps={4} />
    case 'preprocess_upload':
      return <State {...state} steps={4} />
    case 'extract_content':
      return <State {...state} steps={4} />
    case 'transcribe_audio':
      return <State {...state} steps={4} />
    case 'content_analysis':
      return <State {...state} steps={4} />
    default:
      if (state.status==='succeeded') {
        return null
      }
      return <State {...state} steps={4} />
  }
}

interface StateProps {
  transition: string;
  progress?: number;
  total?: number;
  status: string;
  step: number;
  steps: number;
}

function State({ transition, progress, total, status, step, steps }: StateProps) {
  const { t } = useTranslation()
  const value = useMemo(
    () => {
      return ((100 * step + (progress ?? status==='succeeded' ? 100:0)) / steps)
    },
    [progress, status, step, steps]
  )

  return <Stack direction="row" alignItems="center" justifyContent="flex-start">
    <Box width={250} mr={1}><LinearProgress
      color={status==='failed' ? 'error':'info'}
      variant="determinate"
      value={value} /></Box>
    <Typography
      variant="caption">{t(`document:status.${transition}`)}{value!=null && total!=null ? ` ${progress}/${total}`:''}</Typography>
  </Stack>
}
