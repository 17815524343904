import type { Course } from '@goschool/model'
import { CardActionArea } from '@mui/material'
import { CardHeader, CardMedia, Skeleton } from '@mui/material'

import {
  Card, CardActions, styled
} from '@mui/material'
import { GoSchool } from '@goschool/routing'
import { Authorize } from '@goschool/auth'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useCourseContext } from '@goschool/dao'
import {
  CourseActions
} from './CourseActions'


export function CourseCard() {
  const { courseSnapshot } = useCourseContext()
  const course = useMemo(() => courseSnapshot.data(), [courseSnapshot])


  return <StyledCourseCard>
    <CardActionArea component={RouterLink} to={GoSchool.courseChat(courseSnapshot.ref)}>
      <CardHeader title={course.title} subheader={course.code} />
      <CourseCardMedia course={course} />
    </CardActionArea>
    <Authorize roles={['instructor', 'admin']}>
      <CardActions disableSpacing>
        <CourseActions />
      </CardActions>
    </Authorize>
  </StyledCourseCard>
}

function CourseCardMedia({ course }: { course: Course }) {
  if (course.banner!=null) {
    return <CardMedia component="img" image={course.banner} />
  } else {
    return <Skeleton variant="rectangular" height={80} animation={false} />
  }
}


const StyledCourseCard = styled(Card, { name: 'StyledCourseCard', slot: 'Root' })(
  ({ theme }) => ({
    '& .MuiCardHeader-root': {
      overflow: 'hidden',
      '& .MuiCardHeader-content': {
        overflow: 'hidden',
        '& .MuiCardHeader-title': {
          fontSize: theme.typography.body1.fontSize,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '& .MuiCardHeader-subheader': {
          fontSize: theme.typography.caption.fontSize,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    '& .MuiCardMedia-root': {
      height: 80,
    },
    '& .MuiCardContent-root': {
      height: theme.spacing(4),
      overflow: 'hidden',
      '& .MuiTypography-root': {
        overflowY: 'scroll',
        textOverflow: 'ellipsis',
        maxHeight: '100%'
      }
    },
    '& .MuiCardActions-root': {
      overflow: 'hidden'
    }
  })
)
