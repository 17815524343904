import type { PropsWithChildren, ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useUserContext } from './UserContext'
import type { GoSchoolUserRole } from '@goschool/model'


interface AuthorizeProps extends PropsWithChildren {
  roles: GoSchoolUserRole[];
  fallback?: ReactNode;
  contextRoles?: GoSchoolUserRole[];
}

export function Authorize({ children, roles, contextRoles, fallback = null }: AuthorizeProps) {
  const { user, roles: userRoles } = useUserContext()
  const [isAuthorized, setIsAuthorized] = useState(false)
  useEffect(() => {
    if (user!=null) {
      setIsAuthorized(
        roles.some(
          role => userRoles.includes(role)
            &&
            (!contextRoles || contextRoles.includes(role))
          || userRoles.includes('admin')
        )
      )
    }
  }, [user, userRoles, roles, contextRoles])

  return isAuthorized ? children:fallback
}
