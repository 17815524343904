import type { User } from 'firebase/auth'
import type { DocumentReference} from 'firebase/firestore'
import { serverTimestamp, setDoc } from 'firebase/firestore'
import type { Chat} from '@goschool/shared/chat'
import { ChatManager } from '@goschool/shared/chat'
import { useCallback, useEffect, useState } from 'react'
import type { CourseChat } from '@goschool/model'

export function useChatManager(user: User | null | undefined, selectedChat: DocumentReference<Chat> | undefined) {

  const initChat = useCallback(
    async (chatRef: DocumentReference<CourseChat>) => {
      await setDoc(
        chatRef, {
          created_at: serverTimestamp(), updated_at: serverTimestamp(),
          title: null,
          created_by: user?.uid ?? null,
          participants: user!=null ? [user.uid]:[],
          submit_state: 'idle'
        }
      )
    },
    [user]
  )

  const [chatManager, setChatmanager] = useState<ChatManager>()
  useEffect(() => {
    if (selectedChat!==undefined && user!==undefined) {
      const manager = new ChatManager(selectedChat, user, initChat)
      setChatmanager(manager)

      return () => {
        manager.dispose()
      }
    }
  }, [initChat, selectedChat, user])

  return chatManager
}
