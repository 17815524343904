import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'

import * as en from './en'
import * as hu from './hu'

export const defaultNS = 'translations'

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: { en, hu },
    defaultNS,
    ns: ['translations', 'auth', 'document',  'course', 'languages', 'legal', 'landing', 'admin'],
    interpolation: {
      escapeValue: false
    }
  })
