import { Avatar, Card, CardContent, colors, Stack, styled, Typography } from '@mui/material'
import renataNemeth from './assets/renata-nemeth.jpg'
import ritaToth from './assets/rita-toth.jpg'
import student01 from './assets/student-01.jpg'
import student02 from './assets/student-02.jpg'
import student03 from './assets/student-03.jpg'
import student04 from './assets/student-04.jpg'
import { Section } from './Scroller'
import { Trans, useTranslation } from 'react-i18next'

export function Testimonials() {
  return <Section>
    <TestimonialGrid>
      <Testimonial photo={renataNemeth} i18nKey="renataNemeth" />
      <Testimonial photo={student01} i18nKey="student01" />
      <Testimonial photo={student02} i18nKey="student02" />
      <Testimonial photo={student03} i18nKey="student03" />
      {/*<Testimonial photo={ritaToth} i18nKey="ritaToth" />*/}
      <Testimonial photo={student04} i18nKey="student04" />
    </TestimonialGrid>
  </Section>
}

const TestimonialGrid = styled('div')(
  ({ theme }) => ({
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gridGap: theme.spacing(4)
    }
  )
)

interface TestimonialProps {
  photo: string;
  i18nKey: string;
}

function Testimonial(props: TestimonialProps) {
  const { i18nKey } = props
  return <TestimonialCard>
      <CardContent>
        <Typography variant="body2" className="testimonial" gutterBottom={true} flexGrow={1}><Trans
          i18nKey={`landing:testimonials.${i18nKey}.content`} /></Typography>
        <Signature {...props} />
      </CardContent>
    </TestimonialCard>
}

function Signature(props: TestimonialProps) {
  const { photo, i18nKey } = props
  const { t } = useTranslation()
  const { name } = t(`landing:testimonials.${i18nKey}`, { returnObjects: true }) as { name: string }

  return <Stack className="signature">
    <Avatar><img src={photo} alt={name ?? photo} /></Avatar>
    <Typography variant="caption" textAlign="right">{name}</Typography>
  </Stack>
}


const TestimonialCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  flexGrow: 1,
  flexShrink: 1,
  backgroundColor: colors.indigo[900],
  '& .MuiCardContent-root': {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    gap: theme.spacing(1),
    '& .testimonial': {
      flexGrow: 1,
      fontStyle: 'italic'
    },
    '& .signature': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary
      },
      '& .MuiAvatar-root': {
        '& img': {
          width: '100%',
          height: 'auto'
        }
      }
    }
  }
}))
