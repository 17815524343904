import { Fab, Link, Stack, Toolbar, Typography } from '@mui/material'
import {
  Breadcrumbs,
  FixedFab,
  HomeBreadcrumb
} from '@goschool/components'
import { LoadingPage, PageLayout, GoSchoolAppBar } from '@goschool/components'
import { Trans, useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { CoursesGrid } from './components/CoursesGrid'
import type { PropsWithChildren, ReactNode } from 'react'
import { useState } from 'react'
import { AuthButton, Authorize, useUserContext } from '@goschool/auth'
import { CreateCourseDialog } from './components/CreateCourseDialog'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'

export function CoursesPage() {
  const { roles, user, goSchoolUser, state } = useUserContext()
  const [creating, setCreating] = useState(false)
  const { t } = useTranslation()
  if (user===undefined || state==='pending' || goSchoolUser===undefined) {
    return <LoadingPage />
  }

  if (goSchoolUser==null || goSchoolUser.organization==null) {
    return <WarningPage icon={<LocationCityIcon />} title={t('course:warnings.noOrganization.title')}>
      <Trans i18nKey="course:warnings.noOrganization.description" />
      <Typography variant="caption" component="p" textAlign="center"><Link
        href="mailto:info@goschool.ai">info@goschool.ai</Link></Typography>
    </WarningPage>
  }

  if ((goSchoolUser.courses ?? []).length===0) {
    if (roles.includes('instructor')) {
      return <>
        <WarningPage icon={<LibraryBooksIcon />} title={t('course:warnings.noCoursesCreateOne.title')}>
          <>
            <Trans i18nKey="course:warnings.noCoursesCreateOne.description" />
            <Fab color="primary" sx={{ marginLeft: '1rem' }} onClick={() => setCreating(true)}><AddIcon /></Fab>
          </>
        </WarningPage>
        <CreateCourseDialog displayed={creating} hide={() => setCreating(false)} /></>
    } else if (roles.includes('student')) {
      return <WarningPage icon={<MeetingRoomIcon />} title={t('course:warnings.noCoursesJoinOne.title')}>
        <Trans i18nKey="course:warnings.noCoursesJoinOne.description" />
      </WarningPage>
    } else {
      return <WarningPage icon={<LocationCityIcon />} title={t('course:warnings.noOrganization.title')}>
        <Trans i18nKey="course:warnings.noOrganization.description" values={{ email: 'info@goschool.ai' }} />
      </WarningPage>
    }
  }

  return <PageLayout fullScreen={false} breadcrumbs={[]}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:catalog.title" /></Typography>
    <CoursesGrid courseRefs={goSchoolUser.courses ?? []} />
    <Authorize roles={['instructor']}>
      <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
        <AddIcon />
      </FixedFab>
    </Authorize>
    <CreateCourseDialog displayed={creating} hide={() => setCreating(false)} />
  </PageLayout>
}


function WarningPage({ icon, title, children }: PropsWithChildren<{ icon: ReactNode, title: string }>) {
  return <PageLayout fullScreen={true}>
    <GoSchoolAppBar position="static">
      <Toolbar variant="dense" disableGutters={true}>
        <Breadcrumbs separator="›">
          <HomeBreadcrumb />
        </Breadcrumbs>
      </Toolbar>
      <AuthButton />
    </GoSchoolAppBar>
    <Stack flexGrow={1} direction="column" alignItems="center" justifyContent="center">
      <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
        {/*<WarningAvatar>{icon}</WarningAvatar>*/}
        <Typography variant="h3" component="h1">
          {title}
        </Typography>
      </Stack>
      <Typography variant="h5" component="p">{children}</Typography>
    </Stack>
  </PageLayout>
}
