import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState, useMemo } from 'react'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { doc, getDoc } from 'firebase/firestore'
import type { Course, GoSchoolUserRole } from '@goschool/model'
import { useUserContext } from '@goschool/auth'

interface CourseContextType {
  courseSnapshot: QueryDocumentSnapshot<Course>;
  courseRoles: GoSchoolUserRole[];
}

export const CourseContext = createContext<CourseContextType | undefined>(undefined)

interface CourseContextProviderProps extends PropsWithChildren {
  courseSnapshot: QueryDocumentSnapshot<Course>;
}

export function CourseContextProvider({ children, courseSnapshot }: CourseContextProviderProps) {
  const { user } = useUserContext()

  const [isStudent, setIsStudent] = useState<boolean>()
  const [isInstructor, setIsInstructor] = useState<boolean>()

  useEffect(() => {
    if (user!=null) {
      const studentRef = doc(courseSnapshot.ref, 'students', user.uid)
      getDoc(studentRef).then(snapshot => {
        setIsStudent(snapshot?.exists())
      })

      const instructorRef = doc(courseSnapshot.ref, 'instructors', user.uid)
      getDoc(instructorRef).then(snapshot => {
        setIsInstructor(snapshot?.exists())
      })
      return () => {
        setIsStudent(undefined)
        setIsInstructor(undefined)
      }
    }
  }, [courseSnapshot, user])

  const courseRoles = useMemo(() => {
      const roles: GoSchoolUserRole[] = []
      if (isStudent) {
        roles.push('student')
      }
      if (isInstructor) {
        roles.push('instructor')
      }

      if (courseSnapshot.data().owner===user?.uid) {
        roles.push('admin')
      }

      return roles

    }, [courseSnapshot, isInstructor, isStudent, user?.uid]
  )



  return <CourseContext.Provider value={{ courseRoles, courseSnapshot }}>
    {children}
  </CourseContext.Provider>
}

export function useCourseContext() {
  const context = useContext(CourseContext)
  if (context===undefined) {
    throw new Error('useCourseContext must be used within a CourseContextProvider')
  }
  return context
}
