import type { Timestamp } from 'firebase/firestore'

export type SubmitState =
  | 'idle'
  | 'posting'
  | 'pending'
  | 'generating'
  | 'failed'

export interface Chat {
  created_at: Timestamp
  updated_at: Timestamp
  title: string | null
  created_by: string | null
  participants: string[]
  submit_state: SubmitState
}

export interface ChatMessage {
  created_at: Timestamp
  content: string
  type: 'human' | 'agent'
  parent: string | null
}

export interface UserMessage extends ChatMessage {
  type: 'human'
  user: string | null
}

export function isUserMessage(message: ChatMessage): message is UserMessage {
  return message.type==='human'
}

export interface ChatCompletionMessageToolCall {
  id: string
  function: {
    name: string
    arguments: string
  }
  type: 'function'
}

export interface ChatCompletionToolMessageParam {
  content: string
  role: 'tool'
  tool_call_id: string
}

export interface ChatCompletionMessage {
  content?: string
  role: 'assistant'
  tool_calls: ChatCompletionMessageToolCall[]
}

export interface AgentMessage extends ChatMessage {
  type: 'agent'
  status: 'pending' | 'generating' | 'completed' | 'failed'
  user: string | null
  tool_calls?: string[]
  messages?: (ChatCompletionMessage | ChatCompletionToolMessageParam)[]
}

export function isAgentMessage(message: ChatMessage): message is AgentMessage {
  return message.type==='agent'
}

export interface ChatUser {
  uid: string
  name: string
  avatar?: string
}
