import { useFirestore } from '@progos/firebase-chat'
import { doc } from 'firebase/firestore'
import { courseChatConverter } from '@goschool/model'
import { useMemo } from 'react'


export interface CourseChatParams {
  organizationId: string;
  courseId: string;
  chatId: string
}

export function useCourseChatRef({organizationId, courseId, chatId}: Readonly<Partial<CourseChatParams>>) {
  const firestore = useFirestore()

  return useMemo(
    () => {
      if (organizationId!=null && courseId!=null && chatId!=null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId,
          'chats', chatId
        ).withConverter(courseChatConverter)
      }
    }, [organizationId, courseId, chatId, firestore]
  )
}


