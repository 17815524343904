import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { FirebaseContextProvider } from '@progos/firebase-chat'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { HelmetProvider } from 'react-helmet-async'
import '@goschool/i18n'

import { UserContextProvider } from '@goschool/auth'
import { AuthenticationContextProvider } from '@goschool/auth'
import { LandingPage, NotFound } from './app'

import * as Sentry from '@sentry/react'
import { goSchoolTheme } from './theme'
import { CustomGoSchoolContextProvider } from '@goschool/components'
import { GoSchoolRoutes } from '@goschool/site'
import { BrowserRouter } from 'react-router-dom'
import { customConfig, firebaseConfig, sentryConfig, customAuth } from './config'

Sentry.init(sentryConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <StrictMode>
    <HelmetProvider>
      <CustomGoSchoolContextProvider {...customConfig}>
        <ThemeProvider theme={goSchoolTheme}>
          <CssBaseline />
          <FirebaseContextProvider config={firebaseConfig}>
            <UserContextProvider>
              <BrowserRouter>
                <AuthenticationContextProvider customConfigs={customAuth} enabledMethods={['email-password', 'google', 'microsoft']}>
                  <GoSchoolRoutes App={LandingPage} NotFound={NotFound}  />
                </AuthenticationContextProvider>
              </BrowserRouter>
            </UserContextProvider>
          </FirebaseContextProvider>
        </ThemeProvider>
      </CustomGoSchoolContextProvider>
    </HelmetProvider>
  </StrictMode>
)



