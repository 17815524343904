import { EventEmitter } from '../util'
import type {
  DocumentReference,
  DocumentSnapshot,
  Unsubscribe,
  QuerySnapshot
} from 'firebase/firestore'
import {
  onSnapshot,
  collection,
  type QueryDocumentSnapshot,
  serverTimestamp,
  type WithFieldValue,
  addDoc,
  updateDoc
} from 'firebase/firestore'
import type { Chat, ChatMessage, SubmitState } from './types'

import { type UserMessage } from './types'
import type { MessageTree } from './messageThread'
import { buildTree, collectThread, findLatestLeaf } from './messageThread'
import type { User } from 'firebase/auth'
import { typeConverter } from '@progos/firebase-chat'

interface ChatManagerEvents {
  managerStateUpdate: ManagerState;
  threadUpdate: MessageThread;
  submitStateUpdate: SubmitState;
}

export interface MessageTreeNode {
  parent?: MessageTreeNode;
  message: QueryDocumentSnapshot<ChatMessage>;
  children: MessageTreeNode[];
}

export type MessageThread = MessageTreeNode[];
export type ManagerState = 'loading' | 'ready' | 'disposed';

export class ChatManager extends EventEmitter<ChatManagerEvents> {
  private chatSnapshot: DocumentSnapshot<Chat> | undefined
  private unsubscribeChat: Unsubscribe
  private unsubscribeMessages: Unsubscribe
  private messagesSnapshot: QuerySnapshot<ChatMessage> | undefined
  private messageTree: MessageTree | undefined | null
  private selectedThread: MessageTree | undefined | null
  private isDisposed = false

  constructor(
    private readonly chatReference: DocumentReference<Chat>,
    private readonly user: User | null,
    private readonly initChat: (r: DocumentReference<Chat>) => Promise<void>
  ) {
    super()
    this.unsubscribeChat = onSnapshot(
      this.chatReference,
      { includeMetadataChanges: true },
      this.onChatUpdate
    )
    this.unsubscribeMessages = onSnapshot(
      this.messagesCollection,
      { includeMetadataChanges: true },
      this.onMessagesUpdate
    )
  }

  get id() {
    return this.chatReference.id
  }

  get lastMessage(): MessageTreeNode | null {
    if (this.selectedThread!=null) {
      return findLatestLeaf(this.selectedThread)
    } else if (this.messageTree!=null) {
      return findLatestLeaf(this.messageTree)
    } else {
      return null
    }
  }

  readonly post = async (prompt: string) => {
    const chatSnapshot = this.chatSnapshot
    if (this.state!=='ready' || chatSnapshot===undefined) {
      throw new Error('Chat is not loaded')
    }

    const chat = this.chat

    if (chat!=null) {
      if (['posting', 'pending', 'generating'].includes(chat.submit_state)) {
        throw new Error('Chat is already submitting')
      }
    } else {
      await this.initChat(this.chatReference)
    }

    await updateDoc(this.chatReference, { submit_state: 'posting' })


    const parent = this.lastMessage?.message.id ?? null
    const user = this.user?.uid ?? null
    const created_at = serverTimestamp()
    const userMessage: WithFieldValue<UserMessage> = {
      content: prompt,
      type: 'human',
      parent,
      created_at,
      user
    }

    await addDoc(this.messagesCollection, userMessage)
  }

  get state(): ManagerState {
    if (this.chatSnapshot===undefined || this.messagesSnapshot===undefined) {
      return 'loading'
    }

    if (this.isDisposed) {
      return 'disposed'
    }

    return 'ready'
  }

  get submitState(): SubmitState {
    return this.chat?.submit_state ?? 'idle'
  }

  readonly selectThread = (node: MessageTree) => {
    this.selectedThread = node
    this.emit('threadUpdate', this.thread)
  }

  readonly startThread = (node: MessageTree) => {
    this.selectedThread = node
    this.emit('threadUpdate', this.thread)
  }

  readonly dispose = () => {
    this.isDisposed = true
    this.unsubscribeChat()
    this.unsubscribeMessages()
  }

  get chat(): Chat | undefined {
    return this.chatSnapshot?.data()
  }

  get messagesCollection() {
    return collection(this.chatReference, 'messages').withConverter(
      typeConverter<ChatMessage>()
    )
  }

  get thread(): MessageThread {
    if (this.messageTree==null || this.lastMessage==null) {
      return []
    }

    return collectThread(this.lastMessage)
  }

  private readonly onChatUpdate = (snapshot: DocumentSnapshot<Chat>) => {
    if (!snapshot.metadata.hasPendingWrites) {
      const justLoaded = this.chatSnapshot===undefined
      this.chatSnapshot = snapshot
      if (justLoaded) {
        this.emit('managerStateUpdate', this.state)
      }
      this.emit('submitStateUpdate', this.submitState)
    }
  }

  private readonly onMessagesUpdate = (snapshot: QuerySnapshot<ChatMessage>) => {
    if (!snapshot.metadata.hasPendingWrites) {
      this.messagesSnapshot = snapshot
      if (this.messagesSnapshot.empty) {
        this.messageTree = null
      } else {
        this.messageTree = buildTree(snapshot.docs)
        this.emit('threadUpdate', this.thread)
      }
    }
  }
}
