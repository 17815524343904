import type { DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore'
import { addDoc, collection, type DocumentReference, serverTimestamp, Timestamp } from 'firebase/firestore'
import type { GoSchoolInvitation, Organization } from '@goschool/model'
import {
  Box, Button, CircularProgress, Dialog, DialogContent, IconButton,
  Stack, Typography, useMediaQuery, useTheme
} from '@mui/material'
import { Trans } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { GoSchool } from '@goschool/routing'
import { QRCode } from 'react-qrcode-logo'
import { typeConverter, useFirebaseAnalytics, useFirestore, useFirestoreSnapshot } from '@progos/firebase-chat'
import { useUserContext } from '@goschool/auth'
import { logEvent } from 'firebase/analytics'
import { useCourseContext } from '@goschool/dao'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface CourseInvitationDialogProps {
  hide: () => void;
  invitation: DocumentSnapshot<GoSchoolInvitation> | undefined | null;
  isCreating: boolean;
}

export function QRInvitationDialog({ hide, invitation, isCreating }: CourseInvitationDialogProps) {
  const theme = useTheme()
  const isLG = useMediaQuery(theme.breakpoints.up('lg'))

  const size = isLG ? 512:256
  const { courseSnapshot } = useCourseContext()
  const course = useMemo(() => courseSnapshot.data(), [courseSnapshot])

  return <Dialog open={invitation?.exists() ?? isCreating} maxWidth="xl" fullWidth={false} onClose={hide}>
    <DialogContent>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="stretch">
        <Typography flexGrow={0} variant="h6">
          <Trans i18nKey="auth:invitation.form.joinCourse" values={{ courseTitle: course.title }} /></Typography>
        <Stack flexGrow={1} flexShrink={0} gap={2} minHeight={size} minWidth={size} alignItems="center"
               justifyContent="center">{
          invitation?.exists()
            ? <InvitationQR invitation={invitation} size={size} />
            :<CircularProgress />
        }</Stack>
        <Box flexGrow={0}>
          <Button size="small" variant="outlined" onClick={hide}>
            <Trans i18nKey="auth:invitation.form.close" />
          </Button>
        </Box>
      </Stack>
    </DialogContent>
  </Dialog>
}

export function LinkInvitationDialog({ invitation, hide }: CourseInvitationDialogProps) {
  const { courseSnapshot: course } = useCourseContext()

  return <Dialog open={invitation?.exists() ?? false} maxWidth="xl" fullWidth={false} onClose={hide}>
    <DialogContent>
      <Stack direction="column" gap={2} alignItems="center" justifyContent="stretch">
        <Typography flexGrow={0} variant="h6"><Trans i18nKey="auth:invitation.form.inviteToCourse"
                                                     values={{ courseTitle: course.data().title }} /></Typography>
        <Stack flexGrow={1} flexShrink={0} gap={2} alignItems="center" justifyContent="center">{
          invitation?.exists()
            ? <InvitationLink invitation={invitation} />
            :null
        }</Stack>
        <Box flexGrow={0}>
          <Button size="small" variant="outlined" onClick={hide}><Trans
            i18nKey="auth:invitation.form.close" />
          </Button>
        </Box>
      </Stack>
    </DialogContent>
  </Dialog>
}

function InvitationQR({ invitation, size }: { invitation: QueryDocumentSnapshot<GoSchoolInvitation>, size: number }) {
  const url = useMemo(
    () => `${window.location.origin}${GoSchool.invitation(invitation.ref)}`, [invitation]
  )

  const theme = useTheme()


  return <QRCode
    size={size} qrStyle="fluid"
    logoImage="/site-logo.png" logoPaddingStyle="circle" removeQrCodeBehindLogo={true}
    logoWidth={32} logoHeight={32} logoPadding={2}
    bgColor={theme.palette.background.default}
    fgColor={theme.palette.primary.main}
    eyeColor={theme.palette.secondary.main}
    style={{ height: 'auto', maxWidth: size, width: size }}
    value={url}
  />
}


function InvitationLink({ invitation }: { invitation: QueryDocumentSnapshot<GoSchoolInvitation> }) {
  const url = useMemo(
    () => `${window.location.origin}${GoSchool.invitation(invitation.ref)}`, [invitation]
  )

  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(url)
    window.alert('Link copied to clipboard')
  }, [url])
  return <>
    <Stack direction="row" alignItems="center">
      <Typography sx={{ cursor: 'pointer' }} color="primary" variant="body2" onClick={copyUrl}>{url}</Typography>
      <IconButton onClick={copyUrl} color="primary" size="small">
        <ContentCopyIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Stack>

    <Typography variant="caption"><Trans
      i18nKey="auth:invitation.form.inviteLinkDescription" /></Typography>
  </>
}

export function useCourseInvitationGenerator(expirationInHours = 1) {
  const firestore = useFirestore()
  const { user, roles } = useUserContext()
  const { courseSnapshot } = useCourseContext()

  const invitationsCollection = useMemo(
    () => {
      return collection(firestore, 'invitations').withConverter(typeConverter<GoSchoolInvitation>())
    }, [firestore]
  )

  const [invitationRef, setInvitationRef] = useState<DocumentReference<GoSchoolInvitation> | null | undefined>(null)
  const invitation = useFirestoreSnapshot(invitationRef)
  const { analytics } = useFirebaseAnalytics()

  const createInvitation = useCallback(
    async () => {
      if (user==null) {
        throw new Error('User not authenticated')
      }
      if (courseSnapshot.ref.parent.parent==null) {
        throw new Error('Course not in organization')
      }
      if (invitation?.exists) {
        throw new Error('Invitation already exists')
      }
      setInvitationRef(undefined)
      try {
        const ref = await addDoc(invitationsCollection, {
          created_at: serverTimestamp(),
          created_by: user.uid,
          roles: ['student'],
          email: null,
          course: courseSnapshot.ref,
          organization: courseSnapshot.ref.parent.parent.withConverter(typeConverter<Organization>()),
          expires_at: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * expirationInHours)
        })

        logEvent(analytics, 'invitation_created', {
          course_id: courseSnapshot.id,
          invitation_id: ref.id
        })

        setInvitationRef(ref)
      } catch (e) {
        console.error('cannot create invitation', e)
        console.dir({ roles, user })
      }
    }, [user, courseSnapshot, invitation, invitationsCollection, expirationInHours, analytics, roles]
  )

  const reset = useCallback(() => {
    setInvitationRef(null)
  }, [])


  const result = useMemo(() => {
    if (invitationRef!==null || invitation?.exists) {
      return { invitation, isCreating: true }
    } else {
      return { invitation: null, isCreating: false }
    }
  }, [invitation, invitationRef])


  return { createInvitation, reset, ...result }
}
