import { typeConverter } from '@progos/firebase-chat'
import type { CourseChat } from './CourseChat'
import type { Course } from './Course'
import type { CourseDocument, FileUpload } from './CourseDocument'
import type { Organization } from './Organization'

export const courseChatConverter = typeConverter<CourseChat>()
export const courseConverter = typeConverter<Course>()
export const courseDocumentConverter = typeConverter<CourseDocument>()
export const documentUploadConverter = typeConverter<FileUpload>()
export const organizationConverter = typeConverter<Organization>()
