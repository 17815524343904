import { Breadcrumbs as MuiBreadcrumbs, Link, styled, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import { GoSchool } from '@goschool/routing'
import type { PropsWithChildren } from 'react'

export function HomeBreadcrumb() {
  return <Link component={RouterLink} sx={{ display: 'flex', alignItems: 'center' }} to={GoSchool.home}><HomeIcon
    fontSize="small" /></Link>
}

export interface BreadcrumbProps extends PropsWithChildren {
  to: string;
}

export function Breadcrumb({to, children}: BreadcrumbProps) {
  return <Link component={RouterLink} to={to}>
    <Typography variant="body2">{children}</Typography>
  </Link>
}

export const Breadcrumbs = styled(MuiBreadcrumbs, {
  name: 'Breadcrumbs',
  slot: 'Root'
})(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    '& .MuiBreadcrumbs-li': {
      flexShrink: 1,
      flexGrow: 0,
      overflow: 'hidden',
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.secondary.main
      }
    },
    '& .MuiBreadcrumbs-separator': {
      color: theme.palette.secondary.main
    }
  }
}))
