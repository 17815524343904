import { useUserContext } from './UserContext'
import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'
import { SignUpForm, SignInForm, PasswordResetForm } from './components'
import { signOut as firebaseSignOut } from 'firebase/auth'
import { GoSchoolDialog } from '@goschool/mui'
import type { CustomParameters } from 'firebase/auth'


type AuthenticationMode = 'signIn' | 'signUp' | 'resetPassword'
export type AuthProvider = 'google' | 'microsoft' | 'email-password'

export interface AuthenticationContextType {
  authenticationMode: AuthenticationMode | null;

  enabledMethods: AuthProvider[];
  signIn: () => void;
  signUp: () => void;
  signOut: () => void;
  resetPassword: () => void;

  hideAuthentication: () => void;
  customConfigs: Record<string, CustomParameters>;
}

export const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined)


export interface AuthenticationContextProviderProps extends PropsWithChildren {
  enabledMethods: AuthProvider[];
  defaultMode?: AuthenticationMode;
  customConfigs: Record<string, CustomParameters>;
}

export function AuthenticationContextProvider({
                                                enabledMethods, defaultMode, children, customConfigs
                                              }: AuthenticationContextProviderProps) {
  const { auth } = useUserContext()

  const [authenticationMode, setAuthenticationMode] = useState<AuthenticationMode | null>(
    defaultMode ?? null
  )
  const signIn = useCallback(() => setAuthenticationMode('signIn'), [])
  const signUp = useCallback(() => setAuthenticationMode('signUp'), [])
  const resetPassword = useCallback(() => setAuthenticationMode('resetPassword'), [])
  const hideAuthentication = useCallback(() => setAuthenticationMode(null), [])
  const signOut = useCallback(() => {
    firebaseSignOut(auth)
  }, [auth])


  return <AuthenticationContext.Provider
    value={{
      enabledMethods, authenticationMode, signIn, signUp, resetPassword, hideAuthentication, signOut, customConfigs
    }}>
    {children}
    <GoSchoolDialog open={authenticationMode!=null} onClose={hideAuthentication} fullWidth={true}>{
      authenticationMode==='signIn'
        ? <SignInForm />
        :authenticationMode==='signUp'
          ? <SignUpForm />
          :<PasswordResetForm />
    }</GoSchoolDialog>
  </AuthenticationContext.Provider>
}


export function useAuthenticationContext() {
  const context = useContext(AuthenticationContext)
  if (context==null) {
    throw new Error('useAuthenticationContext must be used within a AuthenticationContextProvider')
  }
  return context
}
