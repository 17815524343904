import { useFirebaseAuth } from '@progos/firebase-chat'
import * as Sentry from '@sentry/react'
import { useCallback } from 'react'

export function useSentry() {
  const { user } = useFirebaseAuth()

  return useCallback(
    (error: unknown) => {
      const payload = {
        user: { id: user?.uid, email: user?.email ?? undefined },
        extra: {
          href: window.location.href
        }
      }

      Sentry.captureException(
        error,
        payload
      )
    }, [user]
  )
}
